import { getAnalytics, logEvent } from "@firebase/analytics";
import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logoTry from "../../../Global/assets/Images/logoTry.png";
import CustomLinkTag from "../../../Global/components/Routes/CustomRoute";
import useIsTabletSize from "../../../Global/customHooks/isTabletSizeHook";
import "./BPNavBar.css";

export default function NavBar({
  sticky,
  restrictRedirection = false,
  useAnchorTag = false,
}) {
  NavBar.propTypes = {
    sticky: PropTypes.bool,
    restrictRedirection: PropTypes.bool,
    useAnchorTag: PropTypes.bool,
  };
  const analytics = getAnalytics();
  const [navFixed, setnavFixed] = useState(false);

  const isTabletSize = useIsTabletSize();

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
  }, []);
  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setnavFixed(true);
    } else {
      setnavFixed(false);
    }
  };

  const logAnalyticsClickEvent = (name) => {
    logEvent(analytics, "click-event", {
      content_placement: "navbar",
      content_type: "link",
      content_name: name,
    });
  };

  return (
    <div className={`${(navFixed || sticky) && "is-sticky"}`}>
      <Navbar expand="lg" className="main-navbar-container" fixed="top">
        <Container className="tyn-appbar-wrap">
          <Navbar.Brand
            className="tyn-appbar-logo"
            onClick={() => logAnalyticsClickEvent("logo_navbar")}
          >
            <CustomLinkTag
              classNames="text-decoration-none"
              restrictRedirection={restrictRedirection}
              useAnchorTag={useAnchorTag}
              to="/"
            >
              <div className="tyn-logo">
                {(navFixed || sticky) && isTabletSize ? (
                  <img
                    loading="lazy"
                    alt="nav-bar-logo"
                    src={logoTry}
                    className={`px-3 tyn-logo-img ${
                      navFixed || sticky ? "visible" : ""
                    }`}
                  />
                ) : (
                  <h2>EvaluAItor</h2>
                )}
              </div>
            </CustomLinkTag>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  );
}
