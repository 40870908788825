import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import ProgressiveImage from "../../../../Global/components/ProgressiveImage/ProgressiveImage";
import "./HomeTabsNavDataCard.css";

export default function HomeTabsNavDataCard({
  title,
  description,
  btnTitle,
  btnLink,
  imageSrc,
}) {
  return (
    <>
      <div className="cf-content-part1">
        <h3 className=" cf-content-part1-title __className_af4ca8">{title}</h3>
        <div className=" cf-content-part1-subtitle __className_c70aac my-3 text-justify">
          {description}
        </div>
        <Link className="btn btn-primary mt-4 cf-content-btn" to={btnLink}>
          {btnTitle}
        </Link>
      </div>
      <span className="cf-content-part2">
        <ProgressiveImage
          src={imageSrc}
          placeholderSrc={imageSrc}
          alt={`${title?.toLowerCase()}-interview transcription feature`}
          width="100%"
          height="100%"
          extraClass="border rounded"
        />
      </span>
    </>
  );
}
HomeTabsNavDataCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  btnTitle: PropTypes.string.isRequired,
  btnLink: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
};
