import {
  faGithub,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageHelmet from "../../../Global/components/PageHelmet/PageHelmet";
import useIsTabletSize from "../../../Global/customHooks/isTabletSizeHook";
import akashBonagiri from "../../Assets/Images/TeamImages/AkashBonagiri.webp";
import abhinay from "../../Assets/Images/TeamImages/abhinay.jpeg";
import anshVerma from "../../Assets/Images/TeamImages/anshVerma.webp";
import aswathramK from "../../Assets/Images/TeamImages/aswathramK.webp";
import bosubabuSambana from "../../Assets/Images/TeamImages/bosubabuSambana.webp";
import callahanCarvalho from "../../Assets/Images/TeamImages/callahanCarvalho.webp";
import harshRaj from "../../Assets/Images/TeamImages/harshRaj.webp";
import hridayeshBajaj from "../../Assets/Images/TeamImages/hridayeshBajaj.webp";
import mahendra from "../../Assets/Images/TeamImages/mahendra.webp";
import pranjal from "../../Assets/Images/TeamImages/pranjal.webp";
import reeveSilvera from "../../Assets/Images/TeamImages/reeveSilvera.webp";
import richaTiwari from "../../Assets/Images/TeamImages/richaTiwari.webp";
import sahilLuthra from "../../Assets/Images/TeamImages/sahilLuthra.webp";
import simone from "../../Assets/Images/TeamImages/simone.webp";
import srinivasKonduri from "../../Assets/Images/TeamImages/srinivasKonduri.webp";
import sureDivya from "../../Assets/Images/TeamImages/sureDivya.webp";
import TemporaryNavBar from "../TemporaryHomePage/TemoporaryNavBar/TemoporaryNavBar";
import TemporaryFooter from "../TemporaryHomePage/TemporaryFooter/TemporaryFooter";
import "./OurTeam.css";

export default function OurTeam() {
  const [isHovered2, setIsHovered2] = useState(false);
  const isTabletSize = useIsTabletSize();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const teamMembers = [
    {
      name: "Simone Singh",
      imageSource: simone,
      jobTitle: "Director",
      linkedinUrl: "https://www.linkedin.com/in/simone-singh-67b204263/",
      githubUrl: "https://github.com/simone-28",
      instagramUrl: "https://www.instagram.com/_28simone_/",
      emailAddress: "simone28singh@gmail.com",
    },
    {
      name: "Bosubabu Sambana",
      imageSource: bosubabuSambana,
      jobTitle: "Co-Founder & Director",
      linkedinUrl: "https://www.linkedin.com/in/bosubabu-sambana-2b7a1b17/",
      githubUrl: "",
      instagramUrl: "",
      emailAddress: "bosukalam@gmail.com",
    },
    {
      name: "Hridayesh Bajaj",
      imageSource: hridayeshBajaj,
      jobTitle: "AI Engineer",
      linkedinUrl: "https://www.linkedin.com/in/hridayesh-bajaj-53700b200/",
      githubUrl: "https://github.com/gh0stIridium",
      instagramUrl: "https://www.instagram.com/hridaybajaj_/",
      emailAddress: "f20202343@hyderabad.bits-pilani.ac.in",
    },
    {
      name: "Reeve Silvera",
      imageSource: reeveSilvera,
      jobTitle: "Graphic Designer",
      linkedinUrl: "https://www.linkedin.com/in/reeve-silvera-8a682723b/",
      githubUrl: "",
      instagramUrl: "https://www.instagram.com/reeve_silvera/",
      emailAddress: "reevesilvera23@gmail.com",
    },
    {
      name: "Pranjal Pandey",
      imageSource: pranjal,
      jobTitle: "Head of Client Acquisition",
      linkedinUrl: "https://www.linkedin.com/in/pranjal-p1001/",
      githubUrl: "",
      instagramUrl: "https://www.instagram.com/pran___1000/",
      emailAddress: "",
    },
    {
      name: "Mahendra Lohar",
      imageSource: mahendra,
      jobTitle: "Developer",
      linkedinUrl: "https://www.linkedin.com/in/mahendra-lohar/",
      githubUrl: "https://github.com/Mahendra-Lohar",
      instagramUrl: "https://www.instagram.com/mahendralohar21/",
      emailAddress: "mnlohar18@gmail.com",
    },
    {
      name: "Aswathram K",
      imageSource: aswathramK,
      jobTitle: "Product Intern",
      linkedinUrl: "https://www.linkedin.com/in/aswathram-k-9a3b59223/",
      githubUrl: "https://github.com/AswathramK",
      instagramUrl: "https://www.instagram.com/aswathram_karunanithi/",
      emailAddress: "aswathramk12@gmail.com",
    },
    {
      name: "Harsh Raj Singh Rathore",
      imageSource: harshRaj,
      jobTitle: "AI Engineer",
      linkedinUrl: "https://linkedin.com/heyhrsh/",
      githubUrl: "https://github.com/heyhrsh/",
      instagramUrl: "",
      emailAddress: "rathore.hrsr@gmail.com",
    },
    {
      name: "Sahil Luthra",
      imageSource: sahilLuthra,
      jobTitle: "Product Intern",
      linkedinUrl: "https://www.linkedin.com/in/sahil-luthra-631a45214/",
      githubUrl: "https://github.com/sahilluthra03",
      instagramUrl: "https://www.instagram.com/sahilluthra03/",
      emailAddress: "luthrasahil03@gmail.com",
    },
    {
      name: "Sure Divya sree",
      imageSource: sureDivya,
      jobTitle: "Data Engineering Intern",
      linkedinUrl: "https://www.linkedin.com/in/sure-divya-sree-24909926b/",
      githubUrl: "",
      instagramUrl: "https://www.instagram.com/princess_diply/",
      emailAddress: "divyasreesure21@gmail.com",
    },
    {
      name: "Richa Tiwari",
      imageSource: richaTiwari,
      jobTitle: "Product Intern",
      linkedinUrl: "https://www.linkedin.com/in/richatiwari945b59177/",
      githubUrl: "",
      instagramUrl: "https://www.instagram.com/richaa_tiwarii_",
      emailAddress: "richatiwari459@gmail.com",
    },
    {
      name: "Callahan Carvalho",
      imageSource: callahanCarvalho,
      jobTitle: "Product Intern",
      linkedinUrl: "https://www.linkedin.com/in/callahan-carvalho-a7a320285/",
      githubUrl: "https://github.com/DominicCloud",
      instagramUrl: "https://www.instagram.com/callalan.c",
      emailAddress: "callahancarvalho@gmail.com",
    },
    {
      name: "Ansh Verma",
      imageSource: anshVerma,
      jobTitle: "Product Intern",
      linkedinUrl: "https://www.linkedin.com/in/ansh-verma-6b94aa202/",
      githubUrl: "https://github.com/Anshcoder40",
      instagramUrl: "https://www.instagram.com/anshvrmx",
      emailAddress: "avofficial9@gmail.com",
    },
    {
      name: "Akash Bonagiri",
      imageSource: akashBonagiri,
      jobTitle: "ML Research",
      linkedinUrl: "https://www.linkedin.com/in/akash-bonagiri",
      githubUrl: "https://github.com/BSAkash",
      instagramUrl: "https://www.instagram.com/eternal_antique",
      emailAddress: "bsakash1999@gmail.com",
    },
    {
      name: "Abhinay Naidana",
      imageSource: abhinay,
      jobTitle: "Product Manager",
      linkedinUrl: "https://www.linkedin.com/in/naidana-abhinay-762139240/",
      githubUrl: "https://github.com/abhinayasss",
      instagramUrl: "",
      emailAddress: "",
    },
    {
      name: "Srinivas Konduri",
      imageSource: srinivasKonduri,
      jobTitle: "Intern",
      linkedinUrl: "https://www.linkedin.com/in/srinivas-konduri-54a60a169",
      githubUrl: "https://github.com/Kondurisrinivas",
      instagramUrl: "https://www.instagram.com/srinu.konduri__",
      emailAddress: "srinukonduri7703@gmail.com",
    },
  ];

  return (
    <div className="our-team-page">
      <PageHelmet
        title={"EvaluAItor: Our Team Page"}
        description={
          "Elevate your interview skills with AI. Master software engineering interviews with AI-driven mock interviews, personalized feedback, and instant insights"
        }
      />
      <TemporaryNavBar sticky={true} />
      <section
        id="ourteam"
        className="extra-margin-bottom-coz-of-sticky-footer mt-3 text-center container iphoneSection"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="section-title text-center">MEET OUR TEAM</h1>
              <div className="section-title-border mt-lg-3 mb-2" />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {teamMembers.map((member, index) => (
            <div key={index} className="column col-lg-3 col-md-4 col-sm-6">
              <div
                className={`card mt-3 ${isHovered2 ? "hovered" : ""}`}
                onMouseEnter={() => setIsHovered2(!isHovered2)}
                onMouseLeave={() => setIsHovered2(!isHovered2)}
              >
                <div className="img-container">
                  <img
                    loading="lazy"
                    src={member.imageSource}
                    alt={member.name}
                  />
                </div>
                <h2>{member.name}</h2>
                <h3 className="h6">{member.jobTitle}</h3>
                <div className="icons">
                  <Link
                    to={member.linkedinUrl}
                    target={member.linkedinUrl && "_blank"}
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </Link>
                  <Link
                    to={member.githubUrl}
                    target={member.githubUrl && "_blank"}
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </Link>
                  <Link
                    to={member.instagramUrl}
                    target={member.instagramUrl && "_blank"}
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                  <Link
                    to={member.emailAddress && `mailto:${member.emailAddress}`}
                    target={member.emailAddress && "_blank"}
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <TemporaryFooter isTabletSize={isTabletSize} />
    </div>
  );
}
