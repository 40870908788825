import AOS from "aos";
import "aos/dist/aos.css";
import { getAuth } from "firebase/auth";
import { SnackbarProvider } from "notistack";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import OurTeam from "./Consumer/pages/OurTeam/OurTeam";
import TemporaryHomePage from "./Consumer/pages/TemporaryHomePage/TemporaryHomePage";
import { getDataFromRealtimeDatabase } from "./Consumer/supportFunctions/FirebaseFunctions";
import PageHelmet from "./Global/components/PageHelmet/PageHelmet";
import CustomRouteElement from "./Global/components/Routes/CustomRoute";
import SuspenseFallbackComponent from "./Global/components/SuspenseFallbackComponent/SuspenseFallbackComponent";
import Error404Page from "./Global/pages/Error404Page/Error404Page";
import {
  editCurrentUserTypeInRealTimeDatabase,
  findCurrentUserType,
} from "./Global/utils/AuthFunctions/AuthFirebaseFunctions";
import "./index.css";
const OnboardingModal = lazy(() =>
  import("./Consumer/components/Modals/OnboardingModal/OnboardingModal")
);
const App = () => {
  const dispatch = useDispatch();
  const auth = getAuth();
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [onboardingModalSource, setOnboardingModalSource] = useState("SignIn");
  const handleOnboardingModalHide = () => setShowOnboardingModal(false);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  useEffect(() => {
    var timeoutId;
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        var data;
        while (!data) {
          data = await getDataFromRealtimeDatabase(`/AllUsersData/${user.uid}`);
          if (data) {
            const executeCodeWithDelay = () => {
              if (data.showSignInOnboardingModel) {
                if (data.referredById) {
                  setOnboardingModalSource("Referral");
                } else {
                  setOnboardingModalSource("SignIn");
                }
                setShowOnboardingModal(true);
              } else if (data.showEarnedCreditsOnboardingModel) {
                setOnboardingModalSource("EarnedFromReferral");
                setShowOnboardingModal(true);
              } else if (data.showEarnedJobReferralModel) {
                setOnboardingModalSource("EarnedJobReferral");
                setShowOnboardingModal(true);
              }
            };

            timeoutId = setTimeout(executeCodeWithDelay, 1000);

            const payload = {
              email: data.email,
              uid: data.uid,
              profilepic: data.profilepic,
              displayName: data.displayName,
              profileId: data.profileId,
              phoneNumber: data.phoneNumber,
              userReferralCode: data.userReferralCode,
              unSeenNotificationsCount: data.unSeenNotificationsCount || 0,
            };

            var prevCurrentUserType = data.currentUserType;
            var currentUserTypeResult;
            if (data.email) {
              currentUserTypeResult = await findCurrentUserType(data.email);
              const currentUserType = currentUserTypeResult.subscriptionType;
              if (currentUserType != "normalConsumer") {
                payload.currentUserType = currentUserType;
                payload.subscribedUnderInstitute =
                  currentUserTypeResult.instituteName;
                payload.subscribedUnderInstituteKey =
                  currentUserTypeResult.instituteKey;
                payload.subscribedUnderInstituteLogo =
                  currentUserTypeResult.logo;
              }
              prevCurrentUserType = currentUserType;
              if (currentUserType != data.currentUserType) {
                console.log("User not same");
                await editCurrentUserTypeInRealTimeDatabase(
                  currentUserType,
                  data.uid,
                  "AllUserUUIDAndCurrentUserTypeList",
                  data.uid
                );
                await editCurrentUserTypeInRealTimeDatabase(
                  currentUserTypeResult.instituteKey,
                  "subscribedUnderInstituteKey",
                  "AllUsersData",
                  data.uid
                );
                await editCurrentUserTypeInRealTimeDatabase(
                  currentUserTypeResult.instituteName,
                  "subscribedUnderInstitute",
                  "AllUsersData",
                  data.uid
                );
                await editCurrentUserTypeInRealTimeDatabase(
                  currentUserTypeResult.logo,
                  "subscribedUnderInstituteLogo",
                  "AllUsersData",
                  data.uid
                );
                await editCurrentUserTypeInRealTimeDatabase(
                  currentUserType,
                  "currentUserType",
                  "AllUsersData",
                  data.uid
                );
              }
            }

            if (prevCurrentUserType == "normalConsumer") {
              payload.credits = data.credits;
            }

            dispatch({
              type: "ADD_USER_DATA",
              payload: payload,
            });
          }
        }
      }
    });
    return () => {
      unsubscribe();
      clearTimeout(timeoutId);
    };
  }, [auth, dispatch]);

  const temporaryRoutes = [
    {
      path: "/",
      component: TemporaryHomePage,
      isUserRoute: false,
      isAdminRoute: false,
      hideChatBot: false,
    },
    {
      path: "/team",
      component: OurTeam,
      isUserRoute: false,
      isAdminRoute: false,
      hideChatBot: false,
    },
    {
      path: "/*",
      component: Error404Page,
      isUserRoute: false,
      isAdminRoute: false,
      hideChatBot: false,
    },
  ];

  return (
    <>
      <PageHelmet
        title={"EvaluAItor: Elevate Your Interview Skills with AI"}
        description={
          "Elevate your interview skills with AI. Master software engineering interviews with AI-driven mock interviews, personalized feedback, and instant insights"
        }
      />
      <ToastContainer limit={3} />
      <SnackbarProvider maxSnack={3}>
        <Suspense fallback={<SuspenseFallbackComponent />}>
          <OnboardingModal
            showOnboardingModal={showOnboardingModal}
            handleOnboardingModalHide={handleOnboardingModalHide}
            source={onboardingModalSource}
          />
          <Routes>
            {[...temporaryRoutes].map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <CustomRouteElement
                    path={route.path}
                    isAdminRoute={route.isAdminRoute}
                    isUserRoute={route.isUserRoute}
                    hideChatBot={route.hideChatBot}
                  >
                    <route.component />
                  </CustomRouteElement>
                }
              />
            ))}
          </Routes>
        </Suspense>
      </SnackbarProvider>
    </>
  );
};

export default App;
